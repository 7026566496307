import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import css from "../style/singlepage.module.scss"
import Doc from "../../../components/Doc"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Lottie from "react-lottie"
import * as animationData from "../../../images/lottie/3sBlockLogo.json"
import ExhibitorList from "../../../components/ExhibitorList"

const _lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  className: `ascrg`,
}
const IndexPage = data => {

  return (
    <Layout mode={`about`} lang={`en`}>
      <SEO title={`TRANS BOOKS DOWNLOADs`} lang={`en`} />
      <div className={css.wrap}>
        <div className={css.flexSticky}>
          <div className={css.flexSticky__left}>
            <div className={css.flexSticky__left__heading}>
              <div className={`${css.logoBox} fixedRatioBox is-16x9 border`}>
                <Lottie options={_lottieOptions} width={300} height={180} />
              </div>
            </div>
          </div>
          <div className={css.flexSticky__right}>
            <Doc>
              <h3 style={{ marginTop: 0 }}>Greetings</h3>
              <p>Welcome to TRANS BOOKS DOWNLOADs online bookstore</p>

              <p>
                TRANS BOOKS has been held since 2017 as a book fair to sell all
                kinds of books: electronic, non-electronic, and other types of
                media. It has been held every autumn selling a wide variety of
                “books”.
              </p>

              <p>
                “Books” bridge people and information. While their form has
                changed with the times, books continue to spread. Since books
                reflect the times, TRANS BOOKS was started so we can discover
                more ways of expression and communication.
              </p>

              <p>
                We believe that books are a platform that deeply embraces
                expressions. We have aimed and worked to provide a place where
                you can experience the diversity, potential, and fun of books.
              </p>

              <p>
                Due to COVID-19, our immediate environment has changed
                dramatically. Consequently, the connection between people and
                media, including books, is also changing. The things we have
                been seeing and touching physically are now going online or
                digital. But we may see new possibilities of expression between
                the physical and digital realms.
              </p>

              <p>
                Our team discussed the relationship between current media forms
                and ourselves. We came up with “Download”. “Download” transfers
                the data on the network to our personal computers and
                smartphones, and then from the online space to your daily life.
              </p>

              <p>
                We see “Download” as the bridge between the online space and our
                personal world. In these times, we want you to enjoy and
                experience the wide variety of “books”.
              </p>
            </Doc>
          </div>
        </div>
        <div className={css.flexSticky}>
          <div className={css.flexSticky__left}>
            <div className={css.flexSticky__left__heading}>
              <div className={`fixedRatioBox is-16x9 border`}>
                <h3 className={css.headingText}>Participating artists</h3>
              </div>
            </div>
          </div>
          <div className={css.flexSticky__right}>
            <Doc>
              <div>
                <ExhibitorList lang={`en`} />
              </div>
            </Doc>
          </div>
        </div>
        <div className={css.flexSticky}>
          <div className={css.flexSticky__left}>
            <div className={css.flexSticky__left__heading}>
              <figure>
                <div className={`fixedRatioBox is-16x9 `}>
                  <img
                    className={`border`}
                    src="/assets/img/yu-illustration.png"
                    alt=""
                  />
                </div>
                <figcaption>Illustration by Yu Yamamoto</figcaption>
              </figure>
            </div>
          </div>
          <div className={css.flexSticky__right}>
            <Doc>
              <h3 style={{ marginTop: 0 }}>TRANS BOOKS steering committee</h3>
              <p>
                <OutboundLink href={`https://iimio.com/`} target={`_blank`}>
                  Mio Iizawa
                </OutboundLink>
                （Artist）、
                <OutboundLink
                  href={`https://shunyahagiwara.com/`}
                  target={`_blank`}
                >
                  Shunya Hagiwara
                </OutboundLink>
                （Web Designer / Programmer / Artist）、
                <OutboundLink
                  href={`https://www.hatayurie.com/`}
                  target={`_blank`}
                >
                  Yurie Hata
                </OutboundLink>
                （Graphic Designer）、
                <OutboundLink
                  href={`https://shunyahagiwara.com/`}
                  target={`_blank`}
                >
                  Akiko Saito
                </OutboundLink>
                （Blogger）
              </p>
              <ul>
                <li>
                  <OutboundLink
                    href={`https://transbooks.center/`}
                    target={`_blank`}
                  >
                    Website
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href={`https://www.instagram.com/transbooks/`}
                    target={`_blank`}
                  >
                    Instagram
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href={`https://twitter.com/transbookstrans`}
                    target={`_blank`}
                  >
                    Twitter
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href={`https://www.facebook.com/transbookstrans/`}
                    target={`_blank`}
                  >
                    Facebook Page
                  </OutboundLink>
                </li>
              </ul>

              <hr />

              <h3>
                Notation based on privacy policy and Specified Commercial
                Transactions Law.
              </h3>
              <p>(Only available in Japanese)</p>
              <ul>
                <li>
                  <Link to={"/downloads/privacy-policy/"}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={"/downloads/terms-of-use/"}>Terms of Ues</Link>
                </li>
                <li>
                  <Link to={"/downloads/law/"}>
                    Specified Commercial Transactions Law
                  </Link>
                </li>
              </ul>

              <hr />
              <h3>Contact</h3>
              <p>transbooks.info [at-mark] gmail.com</p>
            </Doc>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default prop => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  artistName
                  alphabetical
                  artistTitle
                }
                fields {
                  slug
                  langKey
                }
                id
              }
            }
          }
        }
      `}
      render={data => <IndexPage site={data}>{prop.children}</IndexPage>}
    />
  )
}
