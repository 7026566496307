import React from "react"
import {Link} from "gatsby"
import { graphql, StaticQuery } from "gatsby"
import css from "./ExhibitorList.module.scss"

const ExhibitorList = data => {
  console.log(data.prop)

  let _queryPosts = []
  for (let i = 0; i < data.site.allMarkdownRemark.edges.length; i += 1) {
    let enty = data.site.allMarkdownRemark.edges[i]
    if (enty.node.fields.langKey === data.prop.lang) {
      if (data.prop.type === `sameGallery`) {
        if (enty.node.frontmatter.sameGallery) {
          _queryPosts.push(enty)
        }
      } else {
        _queryPosts.push(enty)
      }
    }
  }

  _queryPosts.sort((a, b) => {
    const nameA = a.node.frontmatter.alphabetical.toUpperCase()
    const nameB = b.node.frontmatter.alphabetical.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  let result = []
  for (let i = 0; i < _queryPosts.length; i += 1) {
    if (i === 0) {
      result.push(_queryPosts[i])
      continue
    }
    let current = _queryPosts[i].node.frontmatter.alphabetical.toUpperCase()
    let prev = _queryPosts[i - 1].node.frontmatter.alphabetical.toUpperCase()
    if (current !== prev) {
      result.push(_queryPosts[i])
    }
  }

  return (
    <div className={css.base}>
      <ul style={{ listStyle: `none` }}>
        {result.map(({ node }) => (
          <li key={node.id} style={{ textIndent: 0, paddingLeft: 0 }}>
            <Link to={node.fields.slug}>{node.frontmatter.artistName}</Link>
            <p>
              <small>{node.frontmatter.artistTitle}</small>
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default prop => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  artistName
                  alphabetical
                  artistTitle
                  sameGallery
                }
                fields {
                  slug
                  langKey
                }
                id
              }
            }
          }
        }
      `}
      render={data => (
        <ExhibitorList site={data} prop={prop}>
          {prop.children}
        </ExhibitorList>
      )}
    />
  )
}
